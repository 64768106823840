import { Avatar, List, Tooltip } from 'antd';

import React from 'react';
import classNames from 'classnames';
import { useEmotionCss } from '@ant-design/use-emotion-css';
import UserAvatar from '../UserAvatar';

export type NoticeIconTabProps = {
  count?: number;
  showClear?: boolean;
  showViewMore?: boolean;
  style?: React.CSSProperties;
  title: string;
  tabKey: API.NoticeIconItemType;
  onClick?: (item: API.NoticeIconItem) => void;
  onClear?: () => void;
  emptyText?: string;
  clearText?: string;
  viewMoreText?: string;
  list: API.NoticeIconItem[];
  onViewMore?: (e: any) => void;
};
const NoticeList: React.FC<NoticeIconTabProps> = ({
  list = [],
  onClick,
  onClear,
  title,
  onViewMore,
  emptyText,
  showClear = true,
  clearText,
  viewMoreText,
  showViewMore = false,
}) => {
  const extraClass = useEmotionCss(({ token }) => {
    return {
      float: 'right',
      marginTop: -20,
      marginRight: 0,
      color: token.colorTextSecondary,
      fontWeight: 'normal',
    };
  });
  const listClass = useEmotionCss(({ token }) => {
    return {
      height: 253,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '.ant-list-item': {
        padding: 5,
      },
      // '&.loadMore': {
      //   padding: '8 0',
      //   color: token.colorBgTextHover,
      //   textAlign: 'center',
      //   cursor: 'pointer',
      //   '&.loadedAll': {
      //     color: 'rgba(0, 0, 0, 0.25)',
      //     cursor: 'unset',
      //   }
      // }
    };
  });
  const itemClass = useEmotionCss(({ token }) => {
    return {
      paddingRight: 24,
      paddingLeft: 24,
      overflow: 'hidden',
      cursor: 'pointer',
      transition: 'all 0.3s',
      '&:last-child': {
        borderBottom: 0,
      },
      '&:hover': {
        background: token.colorBgTextHover,
      },
    };
  });
  const readClass = useEmotionCss(({ token }) => {
    return {
      opacity: 0.4,
    };
  });
  const notFoundClass = useEmotionCss(({ token }) => {
    return {
      padding: '73px 0 88px',
      color: token.colorBorderSecondary,
      textAlign: 'center',
    };
  });
  const divClass = useEmotionCss(({ token }) => {
    return {
      display: 'inline-block',
      width: '50%',
      cursor: 'pointer',
      transition: 'all 0.3s',
      userSelect: 'none',
      '&:only-child': {
        width: '100%',
      },
      '&:not(:only-child):last-child': {
        borderLeft: 1,
        borderLeftStyle: 'solid',
        borderLeftColor: token.colorSplit,
      },
    };
  });
  const bottomBarClass = useEmotionCss(({ token }) => {
    return {
      height: 35,
      color: token.colorText,
      lineHeight: 3,
      textAlign: 'center',
      borderTop: 1,
      borderTopStyle: 'solid',
      borderTopColor: token.colorSplit,
      transition: 'all 0.3s',
    };
  });

  if (!list || list.length === 0) {
    return (
      <div>
        <div className={notFoundClass}>
          <img
            style={{
              display: 'inline-block',
              height: 76,
              marginBottom: 16,
            }}
            src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
            alt="not found"
          />
          <div>{emptyText}</div>
        </div>
        <div className={bottomBarClass}>
          <div
            className={divClass}
            onClick={(e) => {
              if (onViewMore) {
                onViewMore(e);
              }
            }}
          >
            {viewMoreText}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <List<API.NoticeIconItem>
        className={listClass}
        dataSource={list}
        renderItem={(item, i) => {
          const itemCls = classNames(itemClass, {
            [readClass]: item.isRead,
          });
          // eslint-disable-next-line no-nested-ternary
          const leftIcon = item.userDTO ? (
            <UserAvatar infos={[item?.userDTO]} />
          ) : (
            <Avatar size={'small'} style={{ backgroundColor: '#87d068' }}>
              系统
            </Avatar>
          );

          return (
            <List.Item
              className={itemCls}
              key={item.key || i}
              onClick={() => {
                onClick?.(item);
              }}
            >
              <List.Item.Meta
                avatar={leftIcon}
                title={
                  <div
                    style={{
                      // marginBottom: 8,
                      fontWeight: 'normal',
                      fontSize: 12,
                    }}
                  >
                    <Tooltip title={item.title}>
                      <span
                        style={{
                          width: 240,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          fontWeight: 450,
                        }}
                      >
                        {item.title}
                      </span>
                    </Tooltip>
                    <div className={extraClass}>{item.extra}</div>
                  </div>
                }
                description={
                  <div>
                    {item.relationType !== 'UAT_REPORT' && (
                      <div style={{ fontSize: 11, color: 'rgb(68 68 68)' }}>{item.content}</div>
                    )}
                    <div style={{ fontSize: 11, marginTop: 4 }}>{item.createDate}</div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <div className={bottomBarClass}>
        {showClear ? (
          <div onClick={onClear} className={divClass}>
            {clearText} {title}
          </div>
        ) : null}
        {true ? (
          <div
            className={divClass}
            onClick={(e) => {
              if (onViewMore) {
                onViewMore(e);
              }
            }}
          >
            {viewMoreText}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default NoticeList;
