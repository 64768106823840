import {
  ModalForm,
  ProFormDateRangePicker,
  ProFormDigit,
  ProFormGroup,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
  ProFormItem,
} from '@ant-design/pro-components';
import { useIntl } from '@umijs/max';
import React, { useEffect, useRef, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd';
import { message, Modal, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import 'braft-editor/dist/index.css';
import { listChangeSystem, listUsers, uploadFile } from '@/services/common/api';
import { useModel, useRequest } from '@@/exports';
import { listSelectIssue, listIssueBasicInfo } from '@/services/rd/issues/api';
import moment from 'moment';
import MyTinyMCEEditor from '@/components/MyTinyMCEEditor';
import TextArea from 'antd/es/input/TextArea';

export type FormValueType = {
  target?: string;
  template?: string;
  type?: string;
  time?: string;
  frequency?: string;
} & Partial<API.RuleListItem>;

export type UpdateFormProps = {
  onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalOpen: boolean;
  values: Partial<any>;
  isCreated: boolean;
  detail: any;
  version: boolean;
  issueIds: any;
  systemIdList: any;
  isDisabledPlan: boolean;
};

const myUploadFn = async (param: any) => {
  console.log(param);
  const fd = new FormData();
  fd.append('file', param.file);
  fd.append('permissionType', 'public-read');
  const { resultObject } = await uploadFile(fd);
  if (resultObject) {
    param.success({
      url: resultObject.fileUrl,
      meta: {
        id: resultObject.objectName,
        title: resultObject.fileName,
        alt: 'xxx',
        loop: true, // 指定音视频是否循环播放
        autoPlay: true, // 指定音视频是否自动播放
        controls: true, // 指定音视频是否显示控制栏
        // poster: 'http://xxx/xx.png', // 指定视频播放器的封面
      },
    });
  } else {
    param.error({
      msg: '上传失败！',
    });
  }
};

const SprintModalForm: React.FC<UpdateFormProps> = (props) => {
  const intl = useIntl();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const { initialState, setInitialState } = useModel('@@initialState');
  const issueRef = useRef<ProFormInstance>();
  const [value, setValue] = useState('');
  const [issueBasicInfos, setIssueBasicInfos] = useState([]);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [state, setState] = useState('');

  // 查用户信息
  const { data: userList, run: runUserList } = useRequest(listUsers, { manual: false });

  // 查系统下拉列表
  const { data: systemList, run: runSystemList } = useRequest(() => listChangeSystem(), {
    manual: false,
  });

  // 查需求下拉列表（分页）
  const { data: selectIssueList, run: runSelectIssueList } = useRequest(
    (param) => {
      return listSelectIssue(param);
    },
    { manual: true },
  );

  // 系统变更时，刷新各个下拉数据
  const changeSystem = async () => {
    // 获取系统id集合
    const systemIds =
      issueRef.current?.getFieldValue('systemIdList')?.length > 0
        ? issueRef.current?.getFieldValue('systemIdList')
        : ['null'];

    // 根据系统id过滤选择的需求
    const planIssueIds = issueRef.current?.getFieldValue('planIssueIds');
    if (planIssueIds?.length > 0) {
      // 查选中需求的基础信息
      const selectIssueParam = {
        issueIdList: planIssueIds,
      };
      const { resultObject } = await listIssueBasicInfo(selectIssueParam);

      // 用系统过滤下拉框选中的需求并赋值
      const validPlanIssueIds = resultObject
        ?.filter((u) => systemIds.includes(u.systemId))
        ?.map((i) => i.id);
      issueRef.current?.setFieldValue('planIssueIds', validPlanIssueIds);
    }

    // 刷新需求下拉列表数据
    const param = {
      systemIdList: systemIds,
      issueIds: props?.isCreated ? null : issueRef.current?.getFieldValue('planIssueIds'),
      issueStatusType: props?.isCreated ? 1 : null,
    };
    runSelectIssueList(param);
  };

  function incrementPatchVersion(version: string) {
    // 将版本号分割成数组 ['1', '0', '0']
    const parts = version.split('.');

    // 将修订号部分加1
    const patch = parseInt(parts[2], 10) + 1;

    // 重新组合版本号并返回
    return `${parts[0]}.${parts[1]}.${patch}`;
  }

  const setFields = (detail: any) => {
    issueRef.current?.setFieldsValue({
      planName: detail?.planName || '',
      time: [detail?.startDate, detail?.endDate] || [],
      planManagerCode: detail?.planManagerCode || '',
      estimatedPersonDay: props.version ? '' : detail?.estimatedPersonDay?.toString() || '',
      planIssueIds: props.version ? [] : detail?.planIssueIds || [],
      version: props.version ? incrementPatchVersion(detail?.version) : detail?.version || '',
      systemIdList: detail?.systemIdList || [],
      carbonCopyCodes: detail?.carbonCopyUserCodes || [],
      planGoal: detail?.planGoal || '',
    });
    setFileList(detail?.uploadAttachments);
    setState(detail?.planGoal);
  };

  useEffect(() => {
    if (!props.isCreated) {
      setFields(props.detail);
      runSelectIssueList({
        name: '',
        issueIds: props.detail?.planIssueIds,
        systemIdList:
          issueRef.current?.getFieldValue('systemIdList')?.length > 0
            ? issueRef.current?.getFieldValue('systemIdList')
            : ['null'],
      });
    } else {
      const systemIds = props.isDisabledPlan
        ? props.systemIdList
        : initialState?.defaultSystem?.systemDTO?.systemId === 'all'
        ? []
        : [initialState?.defaultSystem?.systemDTO?.systemId];
      runSelectIssueList({
        issueIds: props.issueIds,
        name: '',
        issueStatusType: 1,
        systemIdList: systemIds,
      });
      setFileList([]);
      setState('');
    }
  }, [props.updateModalOpen, props.detail]);

  const onChange: UploadProps['onChange'] = async ({ file, fileList: newFileList }) => {
    if (!file.status) {
      newFileList.splice(fileList.indexOf(file), 1);
    }
    if (file.status === 'done') {
      let obj = {
        uid: newFileList[newFileList.length - 1].uid,
        name: file.response.fileName,
        status: newFileList[newFileList.length - 1].status,
        url: file.response.fileUrl,
        ...file.response,
      };
      newFileList[newFileList.length - 1] = obj;
    } else if (file.status === 'error') {
      message.error(`${file.name} 文件上传失败`);
    }
    setFileList(newFileList);
  };
  const customRequest: UploadProps['customRequest'] = async ({ file, onSuccess, onError }) => {
    const fd = new FormData();
    fd.append('file', file);
    await uploadFile(fd)
      .then((resp) => {
        // 上传成功时，调用 onSuccess 并传递服务器返回的文件 URL
        onSuccess(resp?.resultObject);
      })
      .catch((error) => {
        // 上传失败时，调用 onError 并传递错误信息
        onError(error);
      });
  };

  return (
    <ModalForm
      formRef={issueRef}
      title={
        props.isCreated
          ? intl.formatMessage({
              id: 'pages.common.create',
              defaultMessage: '创建',
            })
          : intl.formatMessage({
              id: 'pages.common.edit',
              defaultMessage: '编辑',
            })
      }
      width="750px"
      open={props.updateModalOpen}
      onFinish={async (value) => {
        // value.planGoal = myBrafrEditorRef?.current?.description;
        if (value?.planGoal?.length >= 65535) {
          message.error('图片上传错误，请重试！');
          return;
        }
        props.onSubmit(value);
      }}
      onOpenChange={(open) => {
        !open && props.onCancel();
      }}
      modalProps={{ destroyOnClose: true }}
    >
      {/* <ProFormGroup>
        <ProFormItem
          name="issueSn1"
        >
          <Space>
            {intl.formatMessage({
              id: 'pages.sprint.plan',
              defaultMessage: 'Sprint规划',
            })}
            <Tooltip title={'可跨系统选择需求'}>
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        </ProFormItem>

      </ProFormGroup> */}

      <ProFormText
        name="planName"
        label={intl.formatMessage({
          id: 'pages.sprint.name',
          defaultMessage: 'Sprint名称',
        })}
        width="98%"
        bordered={false}
        rules={[
          {
            required: true,
            message: '请输入Sprint名称',
          },
          {
            pattern: /^[^\u4e00-\u9fa5]*$/, // 正则表达式，匹配非中文字符
            message: 'Sprint名称不能包含中文字符',
          },
        ]}
      />

      <ProFormGroup>
        <ProFormSelect
          disabled={props.isDisabledPlan}
          mode={'multiple'}
          showSearch={true}
          name="systemIdList"
          width="md"
          label={intl.formatMessage({
            id: 'pages.system.title',
            defaultMessage: '系统',
          })}
          rules={[
            {
              required: true,
              message: '请选择系统',
            },
          ]}
          onChange={(...row) => changeSystem(row)}
          initialValue={
            initialState?.defaultSystem?.systemDTO?.systemId === 'all'
              ? props?.systemIdList
              : props.isDisabledPlan
              ? props?.systemIdList
              : [initialState?.defaultSystem?.systemDTO?.systemId]
          }
          options={systemList
            ?.filter((u) => u.systemId !== 'all')
            ?.map((item) => ({ label: item.systemName, value: item.systemId, ...item }))}
        />

        <ProFormSelect
          disabled={(!props.isCreated && !props.version) || props.isDisabledPlan}
          showSearch={true}
          colProps={{ md: 12, xl: 8 }}
          mode={'multiple'}
          name="planIssueIds"
          width="md"
          tooltip={'该Sprint包含的需求，一个需求只能属于一个Sprint'}
          label={intl.formatMessage({
            id: 'pages.sprint.form.issues',
            defaultMessage: '需求',
          })}
          initialValue={props.issueIds}
          options={selectIssueList?.results?.map((item: any) => ({
            label: item.issueName,
            value: item.id,
          }))}
          fieldProps={{
            onSearch: (name) => {
              runSelectIssueList({
                name: name,
                issueIds: props.detail?.planIssueIds,
                issueStatusType: 1,
                systemIdList:
                  issueRef.current?.getFieldValue('systemIdList')?.length > 0
                    ? issueRef.current?.getFieldValue('systemIdList')
                    : [],
              });
            },
          }}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSelect
          colProps={{ md: 12, xl: 8 }}
          // mode={'multiple'}
          showSearch={true}
          name="planManagerCode"
          width="md"
          label={intl.formatMessage({
            id: 'pages.sprint.owner',
            defaultMessage: '负责人',
          })}
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={initialState?.currentUser?.userName}
          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode,
          }))}
        />

        <ProFormDateRangePicker
          name="time"
          width="md"
          label={intl.formatMessage({
            id: 'pages.sprint.form.time',
            defaultMessage: '预估时间范围',
          })}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (moment(value).endOf('day').isBefore(moment().startOf('day'))) {
                  return Promise.reject('时间不能小于今天');
                }
                return Promise.resolve();
              },
            },
          ]}
        />

        {/* <ProFormSelect
          showSearch={true}
          mode={'multiple'}
          name="relatedUsers"
          width="md"
          label={intl.formatMessage({
            id: 'pages.issuePool.relatedUser',
            defaultMessage: '相关人',
          })}
          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode
          }))} /> */}
      </ProFormGroup>

      {/* <ProFormGroup> */}
      {/* <ProFormSelect
          showSearch={true}
          name="systemId"
          width="md"
          label={intl.formatMessage({
            id: 'pages.system.title',
            defaultMessage: '系统',
          })}
          onChange={(...row) => changeSystem(row)}
          rules={[{ required: true }]}
          initialValue={initialState?.defaultSystem?.systemDTO.systemId}
          options={systemList?.map((item) => ({ label: item.systemName, value: item.systemId, ...item }))}
        /> */}

      {/* </ProFormGroup> */}
      <ProFormGroup>
        {/* <ProFormDatePicker
          name="pendingCompleteDate"
          width="md"
          label={intl.formatMessage({
            id: 'pages.issuePool.expectDate',
            defaultMessage: '期待日期',
          })}
          fieldProps={{
            disabledDate: (current) => {
              return current && current < moment().startOf('days');
            },
          }}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (moment(value).endOf('day').isBefore(moment().startOf('day'))) {
                  return Promise.reject('时间不能小于今天');
                }
                return Promise.resolve();
              },
            },
          ]}
        /> */}
        <ProFormDigit
          name="estimatedPersonDay"
          tooltip={'参与总人数 * 工时（天）'}
          label={intl.formatMessage({
            id: 'pages.issuePool.form.estimatedPersonDay',
            defaultMessage: '预估人天',
          })}
          width="md"
        />
        <ProFormText
          name="version"
          tooltip={'版本号只能以数字开头结尾并且用小数点连接（如 1.0.0），创建迭代会自动版本+1'}
          label={intl.formatMessage({
            id: 'pages.sprint.version',
            defaultMessage: '版本号',
          })}
          width="md"
          initialValue={'1.0.0'}
          rules={[
            {
              //可以是普通的版本号（如 1.0.0），也可以是有构建号或其他数字子段的版本号（如 1.0.0-alpha 或 1.0.0-20210301）,不填默认为普通版本号叠加
              // pattern: /^\d+\.\d+\.\d+(-[0-9A-Za-z-]+)?$/,
              // message: '格式不正确，(如 1.0.0 或 1.0.0-alpha 或 1.0.0-20210301)',
              pattern: /^\d+\.\d+\.\d+$/,
              message: '格式不正确，(如 1.0.0)',
            },
          ]}
        />
      </ProFormGroup>
      <ProFormSelect
        showSearch={true}
        mode={'multiple'}
        name="carbonCopyCodes"
        label={'邮件抄送人'}
        options={userList?.map((item: any) => ({
          label: item.userName,
          value: item.userCode,
        }))}
      />
      <ProFormItem label={'迭代目标'} name="planGoal" rules={[{ required: true }]}>
        <TextArea rows={4} placeholder="请输入迭代目标" />
        {/* <MyTinyMCEEditor
          data={state}
          readOnly={false}
          onEdit={() => {}}
          onChange={(editorState) => {
            issueRef?.current?.setFieldsValue({ planGoal: editorState });
          }}
          height={400}
          extendControls={undefined}
        /> */}
      </ProFormItem>

      <Modal
        width={'80%'}
        open={previewVisible}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <iframe
          title="链接内容"
          src={previewUrl}
          style={{ width: '100%', height: document.body.offsetHeight - 200, border: 'none' }}
        />
      </Modal>
    </ModalForm>
  );
};

export default SprintModalForm;
