import { useEffect, useRef } from 'react';
import { notification, Button, Tag } from 'antd';
import { useModel } from '@@/exports';
import { history } from '@@/exports';

const NotificationWebSocket = () => {
  const { initialState } = useModel('@@initialState');
  const ws = useRef<WebSocket | null>(null);
  //   const messageCache = useRef(new Set()); // 使用 Set 存储已接收的消息
  const heartbeatTimer = useRef<NodeJS.Timeout>(); // 心跳定时器
  useEffect(() => {
    if (!initialState?.currentUser?.userName) return;

    const wsUrl = `${WS_URL}${initialState.currentUser.userName}_notification`;
    ws.current = new WebSocket(wsUrl);

    // 添加心跳检测
    const startHeartbeat = () => {
      heartbeatTimer.current = setInterval(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
          ws.current.send('heartbeat');
        }
      }, 30000); // 每30秒发送一次心跳
    };

    ws.current.onopen = () => {
      console.log('Notification WebSocket connected');
      startHeartbeat(); // 连接成功后开始心跳
    };
    ws.current.onmessage = (event) => {
      try {
        if (event.data === 'heartbeat') return; // 忽略心跳响应
        const issueIds = event.data;
        console.log('issueIds', issueIds);
        // 检查消息是否已显示
        // if (!messageCache.current.has(issueIds)) {
        //   messageCache.current.add(issueIds);
        notification.info({
          message: '产品验收通知',
          style: { width: 280 },
          description: (
            <div>
              <div>
                您有
                <Tag color="blue" style={{ margin: 6 }}>
                  {issueIds?.split(',')?.length}
                </Tag>
                个需求待验收
              </div>
              <div style={{ fontSize: 11, color: '#808080' }}>
                关闭此通知后，您可以在通知中心查看
              </div>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  history.push('/rd/productIssue', { issueIds });
                  notification.destroy(); // 关闭通知
                }}
              >
                去填写
              </Button>
            </div>
          ),
          placement: 'topRight',
          //   duration: 10,
          duration: null, // 不自动关闭
          //   showProgress: true, //展示进度条
          //   pauseOnHover: true, //鼠标悬浮时暂停
        });
        // }
      } catch (error) {
        console.error('Failed to parse notification message:', error);
      }
    };

    ws.current.onclose = (event) => {
      console.log('Notification WebSocket disconnected', {
        code: event.code,
        reason: event.reason,
        wasClean: event.wasClean,
      });
      // 清理心跳定时器
      if (heartbeatTimer.current) {
        clearInterval(heartbeatTimer.current);
      }
      // 断开连接后的重连逻辑
      setTimeout(() => {
        if (ws.current?.readyState === WebSocket.CLOSED) {
          ws.current = new WebSocket(wsUrl);
        }
      }, 3000);
    };

    ws.current.onerror = (error) => {
      console.error('Notification WebSocket error:', error);
    };

    // 组件卸载时清理
    return () => {
      if (heartbeatTimer.current) {
        clearInterval(heartbeatTimer.current);
      }
      if (ws.current) {
        ws.current.close();
      }
      //   messageCache.current.clear(); // 清除缓存
    };
  }, [initialState?.currentUser?.userName]);

  return null;
};

export default NotificationWebSocket;
