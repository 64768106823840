export default {
  // 'menu.welcome': '欢迎',
  // 'menu.more-blocks': '更多区块',
  // 'menu.home': '首页',

  // 'menu.login': '登录',
  // 'menu.register': '注册',
  // 'menu.register-result': '注册结果',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.dashboard': 'Dashboard',
  // 'menu.dashboard.analysis': '分析页',
  // 'menu.dashboard.monitor': '监控页',

  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',

  'menu.workplace': '工作台',
  'menu.workplace.myWorkplace': '我的工作台',
  'menu.workplace.myTaskDetails': '我的数据透视',
  'menu.workplace.departmentTaskDetails': '团队数据透视',
  'menu.workplace.teamGanttChart': '全员甘特图',
  'menu.workplace.personalGanttChart': '个人甘特图',

  'menu.dashboard.dataDashboard': '产研大盘',
  'menu.dashboard.dataDashboard.productData': '需求交付',
  'menu.dashboard.dataDashboard.country': '国家大盘',
  'menu.dashboard.dataDashboard.industryLine': '行业线大盘',
  'menu.dashboard.dataDashboard.professionalLineData': '研发任务',
  'menu.dashboard.dataDashboard.personalGanttChart': '个人甘特图',
  'menu.dashboard.dataDashboard.teamGanttChart': '全员甘特图',

  'menu.dashboard.organization': '产研组织',
  'menu.dashboard.organization.weekly': '每周三省吾身',
  'menu.dashboard.organization.weekly.send': '我发出的',
  'menu.dashboard.organization.weekly.receive': '我收到的',
  'menu.dashboard.organization.weekly.all': '全部',
  'menu.dashboard.organization.weekly.department': '部门周报',
  'menu.dashboard.organization.weekly.role': '产品周报',
  'menu.dashboard.organization.duty': '值班表',
  'menu.dashboard.organization.team': '人员分布',
  'menu.dashboard.organization.team.structure': '组织架构',
  'menu.dashboard.organization.team.structure.rd': '研发中心',
  'menu.dashboard.organization.team.structure.operation': '产品运营',
  'menu.dashboard.organization.team.assignment': '人员配置',

  'menu.projectSet': '项目管理',
  'menu.projectSet.issue': '我的需求',
  'menu.projectSet.task': '我的任务',
  'menu.projectSet.team.projectSet': '项目列表',
  'menu.projectSet.team.teamTeam': '项目总览',
  'menu.projectSet.team': '项目信息',

  'menu.rd': '研发协同',
  // 'menu.rd.issues': '需求管理',
  'menu.rd.businessIssue': '业务需求',
  'menu.rd.productIssue': '产品需求',
  'menu.rd.productIssueCreate': '创建编辑',
  'menu.rd.issueSearch': '需求搜索',
  'menu.rd.sprint': '迭代规划',
  'menu.rd.detail': '详情',
  'menu.rd.planning': '规划',
  'menu.rd.issues.poolShareDetail': '需求预览',
  'menu.rd.review': '会议中心',
  // 'menu.rd.process': '研发过程',
  'menu.rd.issues': '需求',
  'menu.rd.tasks': '任务中心',
  'menu.rd.test': '质量管理',
  'menu.rd.test.case': '测试用例',
  'menu.rd.test.minder': '用例详情',
  'menu.rd.test.bug': '测试缺陷',
  'menu.rd.test.testBugCharts': '研发过程质量',

  'menu.rd.deliveryQuality': '交付质量',
  'menu.rd.deliveryQuality.onlineBugs': '线上问题',
  'menu.rd.deliveryQuality.businessAcceptance': '业务验收',
  'menu.rd.deliveryQuality.productAcceptance': '产品验收',
  'menu.rd.plan': '规划',
  'menu.rd.statistics': '统计',

  'menu.chat': 'NextChat',
  'menu.chat.gemini': 'Gemini Pro',
  'menu.chat.qwenPlus': 'Qwen Plus',

  'menu.test': '测试管理',
  'menu.test.case': '测试用例',
  'menu.test.minder': '用例详情',
  'menu.test.report': '测试报告',

  'menu.rd.test.bugCreate': '创建缺陷',
  'menu.rd.test.bugEdit': '编辑缺陷',
  'menu.rd.onlineBugCreate': '创建',
  'menu.rd.onlineBugEdit': '编辑',

  // 'menu.delivery': '交付看板',
  'menu.delivery': '发布看板',
  'menu.delivery.view': '交付视图',
  'menu.delivery.services': '服务视图',
  'menu.delivery.record': '发布记录',
  'menu.delivery.gray': '灰度观测',
  'menu.delivery.appointment': '发布日历',
  'menu.delivery.k8s': 'K8s集群',
  'menu.delivery.service': '服务详情',
  'menu.delivery.revision': '版本详情',

  'menu.dashboard.efficiency': '效能洞察',
  'menu.dashboard.efficiency.dashboard': '需求交付概览',
  'menu.dashboard.efficiency.dashboard.dashboard': '需求交付概览',
  'menu.dashboard.efficiency.dashboard.requirementDashboard': '需求数据看板',
  'menu.dashboard.efficiency.dashboard.businessDashboard': '业务需求看板',
  'menu.dashboard.efficiency.workplace': '交付效率看板',
  'menu.dashboard.efficiency.cost': '成本透视',
  'menu.dashboard.efficiency.issueAndBug': '需求缺陷明细',

  'menu.dashboard.efficiency.scanner': '代码质量',
  'menu.dashboard.efficiency.quality': '质量洞察看板',
  'menu.dashboard.efficiency.quality.testBugCharts': '研发过程质量',
  'menu.dashboard.efficiency.quality.bugProcess': 'Bug日清',
  'menu.dashboard.efficiency.quality.onlineBugCharts': '线上交付质量',

  'menu.dashboard.efficiency.service': '交付能力洞察',
  'menu.dashboard.efficiency.service.serviceStatistics': '发布概览',
  'menu.dashboard.efficiency.service.serviceEmergencyStatistics': '紧急发布概览',
  'menu.dashboard.efficiency.service.serviceCntBar': '发布趋势',

  'menu.dashboard.efficiency.performance': '效能度量评分',
  'menu.dashboard.efficiency.performance.business': '效能度量评分',
  'menu.dashboard.efficiency.performance.personal': '效能度量员工评分',
  'menu.dashboard.efficiency.scanner.sonar': '静态扫描',
  'menu.dashboard.efficiency.scanner.openSCA': '代码风险',

  'menu.dashboard.efficiency.commit': '代码提交',
  'menu.dashboard.efficiency.commit.commitStatistics': '提交统计',
  'menu.dashboard.efficiency.commit.commitRecord': '提交记录',
  'menu.dashboard.efficiency.commit.commitChart': '提交统计图表',
  'menu.dashboard.efficiency.commit.sonar': '静态扫描',
  'menu.dashboard.efficiency.commit.openSCA': '代码风险',

  'menu.dashboard.efficiency.attendance': '工时数据',
  'menu.dashboard.efficiency.attendance.attendanceData': '考勤数据',
  'menu.dashboard.efficiency.attendance.workHourStatistics': '工时统计',
  'menu.dashboard.efficiency.attendance.workHourChart': '工时图表',

  'menu.question': '问题看板',
  'menu.question.online': '线上问题池',
  'menu.question.record': '事故复盘记录',
  'menu.tools': 'Tools',
  'menu.tools.logCenter': '德国日志中心',
  'menu.tools.notProd': '测试环境日志',
  'menu.tools.webLog': 'WEB日志中心',
  'menu.tools.developLog': 'Develop日志中心',
  'menu.tools.iTopLog': 'iTop日志中心',
  'menu.tools.sls': 'SLS',
  'menu.tools.arms': 'ARMS链路分析',
  'menu.tools.clickVisual': 'ClickVisual',
  'menu.tools.polarDB': 'SlowSql',
  'menu.settings': '设置',
  'menu.settings.label': '标签管理',
  'menu.settings.status': '状态数据',
  'menu.settings.statusType': '状态管理',
  'menu.settings.rejectNode': '驳回节点管理',
  'menu.settings.industry': '行业线管理',
  'menu.settings.service': '服务管理',
  'menu.settings.business': '业务领域',
};
